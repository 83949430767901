.notification-container-top-center {
    position: fixed;
    z-index: 8000;
    transform: translateX(-50%);
    top: 20px;
    left: 50%
}

.notification-container-bottom-center {
    position: fixed;
    z-index: 8000;
    transform: translateX(-50%);
    margin-bottom: -20px;
    bottom: 20px;
    left: 50%
}

.notification-container-top-left {
    position: fixed;
    left: 20px;
    top: 20px;
    z-index: 8000
}

.notification-container-top-right {
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 8000
}

.notification-container-bottom-left {
    position: fixed;
    left: 20px;
    bottom: 20px;
    z-index: 8000;
    margin-bottom: -20px
}

.notification-container-bottom-right {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 8000;
    margin-bottom: -20px
}

.notification-container-mobile-top {
    z-index: 8000;
    position: fixed;
    right: 20px;
    left: 20px;
    top: 20px
}

.notification-container-mobile-bottom {
    z-index: 8000;
    position: fixed;
    right: 20px;
    left: 20px;
    bottom: 5px
}

.notification-default {
    /*ORIGINAL COLOR*/
    /*background-color: #007bff;*/
    background-color: #28a745;
    /*border-left: 8px solid #0056b3*/
}

.notification-success {
    background-color: #28a745;
    /*border-left: 8px solid #19692c*/
}

.notification-danger {
    background-color: #dc3545;
    /*border-left: 8px solid #a71d2a*/
}

.notification-info {
    background-color: #17a2b8;
    /*border-left: 8px solid #0f6674*/
}

.notification-warning {
    background-color: #eab000;
    /*border-left: 8px solid #9e7600*/
}

.notification-awesome {
    background-color: #685dc3;
    /*border-left: 8px solid #44399a*/
}

.notification-close span,
.notification-message,
.notification-title {
    color: #fff
}

.notification-item,
.notification-item-root,
.notification-message,
.notification-title {
    opacity: 0.95;
}

.notification-close {
    font-size: 18px;
    position: absolute;
    right: 10px;
    top: 0;
    display: none;
}

.nc-center {
    top: 50%;
    left: 50%;
    position: fixed;
    z-index: 8000;
    pointer-events: all
}

.nc-box {
    left: -50%;
    position: relative;
    transform: translateY(-50%)
}

.notification-container-mobile-bottom .notification-item,
.notification-container-mobile-bottom .notification-item-root,
.notification-container-mobile-top .notification-item,
.notification-container-mobile-top .notification-item-root {
    max-width: 100%;
    width: 100%
}

.notification-item {
    display: flex;
    position: relative;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, .5);
}

.notification-container-bottom-right .notification-item-root,
.notification-container-top-right .notification-item-root {
    margin-left: auto
}

.notification-container-bottom-left .notification-item-root,
.notification-container-top-left .notification-item-root {
    margin-right: auto
}

.notification-container-mobile-bottom .notification-item-root,
.notification-container-mobile-top .notification-item-root {
    margin-left: auto;
    margin-right: auto
}

.notification-item-root {
    width: 275px;
    margin-bottom: 15px
}

.notification-title {
    font-weight: 700;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px
}

.notification-message {
    max-width: calc(100% - 15px);
    font-size: 14px;
    line-height: 150%;
    word-wrap: break-word;
    margin-bottom: 0;
    margin-top: 0
}

.notification-invisible {
    visibility: hidden;
    max-width: 375px
}

.notification-visible {
    visibility: visible
}

.notification-content {
    padding: 15px 21px;
    display: inline-block;
    width: 100%
}
